import useActiveWeb3React from './useActiveWeb3React';
import { useMemo } from 'react';
import { ChainId, IConnectionConfig } from '../interfaces/connection-config.interface';
import multi_ERC1155 from '../constants/abis/MultiERC1155.json';
import MULTIERC20_ABI from '../constants/abis/MultiERC20.json';
import TOKEN_LOCKER_FACTORY_ABI from '../constants/abis/TokenLockerFactory.json';
import multi_ERC721 from '../constants/abis/MultiERC721.json';
import GENERATOR_ABI from '../constants/abis/ContractGenerator.json';
import PRESALE_FACTORY_ABI from '../constants/abis/PresaleFactory.json';
import PRESALE_ABI from '../constants/abis/Presale.json';
import MulticallABI from '../constants/abis/Multicall.json';
import XTT_FAUCET_ABI from '../constants/abis/XTTFaucet.json';
import { CHAIN_INFO } from '../constants/chains';
import {
  EMPTY_ADDRESSES,
  GENERATOR_ADDRESS,
  LP_TOKENLOCKER_FACTORY__ADDRESS,
  MULTICALL_ADDRESS,
  MULTIERC20__ADDRESS,
  MULTIERC721__ADDRESS,
  MULTISEND_1155__ADDRESS,
  PRESALE_FACTORY_ADDRESS,
  TOKENLOCKER_FACTORY__ADDRESS,
  XTT_FAUCET,
} from '../constants/addresses';

const { PROD, TEST } = ChainId;

export const useConnectionConfig = (): IConnectionConfig => {
  const { chainId } = useActiveWeb3React();

  const chainIdValue = useMemo<ChainId>(() => {
    if (chainId === undefined) {
      return PROD;
    }
    return chainId;
  }, [chainId]);

  return useMemo<IConnectionConfig>(
    () => ({
      ...CHAIN_INFO[chainIdValue],
      multiERC1155: {
        address: MULTISEND_1155__ADDRESS[chainIdValue],
        abi: multi_ERC1155.abi,
      },
      multiERC20: {
        address: MULTIERC20__ADDRESS[chainIdValue],
        abi: MULTIERC20_ABI.abi,
      },
      tokenLockerFactory: {
        address: TOKENLOCKER_FACTORY__ADDRESS[chainIdValue],
        abi: TOKEN_LOCKER_FACTORY_ABI.abi,
      },
      lpTokenLockerFactory: {
        address: LP_TOKENLOCKER_FACTORY__ADDRESS[chainIdValue],
        abi: TOKEN_LOCKER_FACTORY_ABI.abi,
      },
      multiERC721: {
        address: MULTIERC721__ADDRESS[chainIdValue],
        abi: multi_ERC721.abi,
      },
      generator: {
        abi: GENERATOR_ABI.abi,
        address: GENERATOR_ADDRESS[chainIdValue],
      },
      presaleFactory: {
        abi: PRESALE_FACTORY_ABI.abi,
        address: PRESALE_FACTORY_ADDRESS[chainIdValue],
      },
      presale: {
        abi: PRESALE_ABI.abi,
        address: EMPTY_ADDRESSES[chainIdValue],
      },
      multicall: {
        abi: MulticallABI,
        address: MULTICALL_ADDRESS[chainIdValue],
      },
      xttFaucet: {
        abi: XTT_FAUCET_ABI,
        address: XTT_FAUCET[chainIdValue],
      },
    }),
    [chainIdValue],
  );
};
