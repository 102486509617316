import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useEagerConnect from '../hooks/useEagerConnect';
import useTheme from '../hooks/useTheme';
import useEagerFetchData from '../hooks/useEagerFetchData';
import PageLoading from '../components/PageLoading';
import Web3ReactManager from '../components/Web3ReactManager';
import WalletModal from '../components/WalletModal';
import FirstStep from './first-step';
const Home = lazy(() => import('./home'));
const Secret = lazy(() => import('./secret'));

function App() {
  useEagerFetchData();
  useEagerConnect();
  useTheme();

  const isDark = localStorage.getItem('isDark');
  // only light mode
  localStorage.setItem('isDark', JSON.stringify(false));
  if (isDark !== 'false') {
    localStorage.setItem('isDark', JSON.stringify(false));
  }

  return (
    <Web3ReactManager>
      <BrowserRouter>
        <WalletModal />
        <Suspense fallback={<PageLoading />}>
          <Routes>
            <Route path='/' element={<FirstStep />} />
            <Route path='/home' element={<Home />} />
            <Route path='/secret' element={<Secret />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Web3ReactManager>
  );
}

export default App;
