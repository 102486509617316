import { ChainId } from '../interfaces/connection-config.interface';
import { XSP_ADDRESS, XTT_ADDRESS } from './addresses';

export const MAX_UINT256 =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const BLOCKED_ADDRESSES: string[] = ['0x7F367cC41522cE07553e823bf3be79A889DEbe1B'];

export const zeroAddress = '0x0000000000000000000000000000000000000000';

export const burnAddresses: string[] = [zeroAddress, '0x000000000000000000000000000000000000dEaD'];

export const connectorLocalStorageKey = 'connectorId';
export const walletLocalStorageKey = 'wallet';

export const UTILITY_TOKEN = {
  name: 'GMNU',
  symbol: 'GMNU',
};
export const APP_NAME = 'GMN';

export interface TokenInfo {
  decimals: number;
  symbol: string;
  name: string;
  image: string;
}

export interface FullTokenInfo extends TokenInfo {
  address: string;
}

export const Tokens: FullTokenInfo[] = [
  {
    address: XTT_ADDRESS[ChainId.PROD],
    name: 'XSwap Treasure Token',
    symbol: 'XTT',
    decimals: 18,
    image:
      'https://github.com/pro100skm/xdc-token-list/blob/master/assets/0x17476dc3eda45aD916cEAdDeA325B240A7FB259D/logo.png?raw=true',
  },
  {
    address: XSP_ADDRESS[ChainId.PROD],
    name: 'XSwap Protocol',
    symbol: 'XSP',
    decimals: 18,
    image:
      'https://github.com/pro100skm/xdc-token-list/blob/master/assets/0x36726235dAdbdb4658D33E62a249dCA7c4B2bC68/logo.png?raw=true',
  },
];
