import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ALL_SUPPORTED_CHAIN_IDS, CHAIN_INFO } from '../chains';
import { ChainId } from '../../interfaces/connection-config.interface';
import { NetworkConnector } from './NetworkConnector';

export const NETWORK_URLS: { [key in ChainId]: string } = {
  [ChainId.PROD]: CHAIN_INFO[ChainId.PROD].rpcUrls[1],
  [ChainId.TEST]: CHAIN_INFO[ChainId.TEST].rpcUrls[1],
};

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: ChainId.PROD,
});

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
});

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: [ChainId.PROD],
  rpc: {
    [ChainId.PROD]: CHAIN_INFO[ChainId.PROD].rpcUrls[1],
  },
  qrcode: true,
  infuraId: undefined,
  chainId: ChainId.PROD,
});
