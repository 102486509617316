import { BigNumber } from '@ethersproject/bignumber';
import { createReducer } from '@reduxjs/toolkit';

import { fetchData, fetchDataSuccess } from './actions';
import { Status } from '../../interfaces/statuses';

export interface IXttPresaleState {
  token: string;
  startTime: number;
  endTime: number;
  hardCapEthAmount: string;
  totalDepositedEthBalance: string;
  minDepositEth: string;
  maxDepositEth: string;
  tokenPerETH: string;
  claimEnabledStart: number; // timestamp
  totalBought: string;
  totalClaimed: string;
  bonus: number;
  deposits: string;
  balanceOf: string;
  status: Status;
  statusWithSigner: Status;
}

export interface IXttPresaleFormattedState
  extends Omit<
    IXttPresaleState,
    | 'hardCapEthAmount'
    | 'totalDepositedEthBalance'
    | 'minDepositEth'
    | 'maxDepositEth'
    | 'tokenPerETH'
    | 'totalBought'
    | 'totalClaimed'
    | 'deposits'
    | 'balanceOf'
  > {
  hardCapEthAmount: BigNumber;
  totalDepositedEthBalance: BigNumber;
  minDepositEth: BigNumber;
  maxDepositEth: BigNumber;
  tokenPerETH: BigNumber;
  totalBought: BigNumber;
  totalClaimed: BigNumber;
  deposits: BigNumber;
  balanceOf: BigNumber;
}

export const initialState: IXttPresaleState = {
  balanceOf: '',
  claimEnabledStart: 0,
  deposits: '',
  hardCapEthAmount: '',
  maxDepositEth: '',
  minDepositEth: '',
  endTime: 0,
  startTime: 0,
  tokenPerETH: '',
  totalBought: '',
  totalClaimed: '',
  totalDepositedEthBalance: '',
  token: '',
  bonus: 0,
  status: Status.INITIAL,
  statusWithSigner: Status.INITIAL,
};

export default createReducer<IXttPresaleState>(initialState, (builder) =>
  builder
    .addCase(fetchData, (state) => ({
      ...state,
      status: Status.PENDING,
    }))
    .addCase(fetchDataSuccess, (state, { payload }) => ({
      ...state,
      ...payload,
    })),
);
