import { CheckCircle, Triangle } from 'react-feather';
import styled from 'styled-components/macro';

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useAllTransactions } from '../../store/transactions/hooks';
import { ExternalLink } from '../../theme';
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink';
import Loader from '../Loader';
import { RowFixed } from '../Row';
import { TransactionSummary } from './TransactionSummary';

const TransactionStatusText = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  max-width: 100%;
  :hover {
    text-decoration: underline;
  }
`;

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.primary1};
`;

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) =>
    pending ? theme.primary1 : success ? theme.green1 : theme.red1};
`;

export default function Transaction({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React();
  const allTransactions = useAllTransactions();

  const tx = allTransactions?.[hash];
  const info = tx?.info;
  const pending = !tx?.receipt;
  const success =
    !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined');

  if (!chainId) return null;

  return (
    <div>
      <TransactionState
        href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
        pending={pending}
        success={success}
      >
        <RowFixed>
          <TransactionStatusText>
            <TransactionSummary info={info} /> ↗
          </TransactionStatusText>
        </RowFixed>
        <IconWrapper pending={pending} success={success}>
          {pending ? <Loader /> : success ? <CheckCircle size='16' /> : <Triangle size='16' />}
        </IconWrapper>
      </TransactionState>
    </div>
  );
}
