import React, { useEffect, useMemo, useState } from 'react';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import '../home/css/styles.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/img/logo.svg';
import { useWalletModalToggle } from '../../store/application/hooks';
import agent from '../../api/agent';
import Web3Status from '../../components/Web3Status';
import metamask from '../../assets/metamask.png';
import walletConnect from '../../assets/walletConnectIcon.svg';
import { ButtonPrimary } from '../../components/Common/Button';
import { Status } from '../../interfaces/statuses';
import { Web3Provider } from '@ethersproject/providers/src.ts/web3-provider';
import { useTransactionAdderWithCallback } from '../../store/transactions/hooks';
import { TransactionResponse } from '@ethersproject/abstract-provider/src.ts';
import { TransactionType } from '../../store/transactions/actions';
import { toast } from 'react-toastify';
import { floorStringNumber } from '../../utils/stringNumber';
import { formatEther } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { BIG_ZERO } from '../../utils/bigNumber';

export const getFreeXDC = async ({
  library,
  account,
}: {
  library: Web3Provider;
  account: string;
}) => {
  let signature;
  const timestamp = Date.now().toString();
  const data = {
    wallet: account,
    timestamp: timestamp,
  };
  try {
    signature = await library.getSigner().signMessage(JSON.stringify(data));
  } catch (e) {
    console.error(e);
    return;
  }
  try {
    const res = await agent.FreeXDC.getXDC({
      ...data,
      signature,
    });
    if (res.txId) {
      return { hash: res.txId } as unknown as TransactionResponse;
    }
  } catch (e: any) {
    toast.error(`${e.response?.data?.reason || 'Error'}. Contact support.`);
    console.error(e.response?.data?.reason);
  }
};

const FirstStep = () => {
  const { account, library } = useActiveWeb3React();
  const navigate = useNavigate();
  const toggleWalletModal = useWalletModalToggle();
  const [getXdcStatus, setGetXdcStatus] = useState(Status.INITIAL);
  const addTransactionWithCallback = useTransactionAdderWithCallback();
  const [balance, setBalance] = useState<BigNumber>(BIG_ZERO);

  const balanceView = useMemo(() => {
    return floorStringNumber(formatEther(balance), 2);
  }, [balance]);
  useEffect(() => {
    if (library && account) {
      library.getBalance(account).then((v) => {
        setBalance(v);
      });
    }
  }, [library, account]);

  const handleGetFreeXDC = async () => {
    if (!library || !account) return;
    setGetXdcStatus(Status.PENDING);
    try {
      const res: TransactionResponse | undefined = await getFreeXDC({ library, account });
      setGetXdcStatus(Status.SUCCESS);
      if (res) {
        addTransactionWithCallback(res, { type: TransactionType.GET_FREE_XDC });
      }
    } catch (e) {
      setGetXdcStatus(Status.ERROR);
      console.error(e);
    }
  };

  return (
    <div>
      <div className='wrapper justify-content-center tokens-withdraw-wrapper'>
        <div className='tokens-withdraw'>
          <div className='tokens-withdraw__images'>
            <a href='#' className='logo'>
              <img src={logo} alt='GM Net' />
            </a>
            <img className='metamask-img' src={metamask} alt='Metamask' />
            <img className='metamask-img' src={walletConnect} alt='Wallet Connect' />
          </div>

          <div className='tokens-withdraw__title'>Withdraw and Stake GMN</div>
          {account ? (
            <>
              <div className='tokens-withdraw__email mb-40'>
                <div className='buttons-column'>
                  <Web3Status />
                </div>
              </div>

              {balance.eq(0) && (
                <div>
                  <div className='buttons-column mb-48'>
                    <ButtonPrimary
                      disabled={getXdcStatus === Status.PENDING}
                      className='button'
                      onClick={handleGetFreeXDC}
                      width='100%'
                    >
                      Get Free 1 XDC Coin For Fees
                    </ButtonPrimary>
                  </div>
                </div>
              )}

              <div>
                <div className='buttons-column mb-48'>
                  <ButtonPrimary width='100%' onClick={() => navigate('/home')}>
                    Withdraw GMN
                  </ButtonPrimary>
                  <ButtonPrimary disabled width='100%' onClick={() => navigate('/home')}>
                    Stake GMN
                  </ButtonPrimary>
                </div>
              </div>
            </>
          ) : (
            <div className='buttons-column mb-48'>
              <ButtonPrimary width='100%' onClick={toggleWalletModal}>
                Connect Wallet
              </ButtonPrimary>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
