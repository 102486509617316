import { TransactionResponse } from '@ethersproject/providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AppState, useAppDispatch, useAppSelector } from '../index';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { addTransaction, addCallbackInfo, TransactionInfo, TransactionType } from './actions';
import { CallbackInfo, TransactionDetails } from './reducer';
import { useSelector } from 'react-redux';

// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder(): (
  response: TransactionResponse,
  info: TransactionInfo,
) => void {
  const { chainId, account } = useActiveWeb3React();
  const dispatch = useAppDispatch();

  //const logMonitoringEvent = useTransactionMonitoringEventCallback();

  return useCallback(
    (response: TransactionResponse, info: TransactionInfo) => {
      if (!account) return;
      if (!chainId) return;
      const hash = response.hash;

      if (!hash) {
        throw Error('No transaction hash found.');
      }
      dispatch(addTransaction({ hash, from: account, info, chainId }));

      //logMonitoringEvent(info, response);
    },
    [account, chainId, dispatch /*, logMonitoringEvent*/],
  );
}

// returns all the transactions for the current chain
const emptyAllTxobj = {};
export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  const { chainId } = useActiveWeb3React();

  const state = useAppSelector((state) => state.transactions);

  return chainId ? state[chainId] ?? emptyAllTxobj : emptyAllTxobj;
}

export function useTransaction(transactionHash?: string): TransactionDetails | undefined {
  const allTransactions = useAllTransactions();

  if (!transactionHash) {
    return undefined;
  }

  return allTransactions[transactionHash];
}

export function useIsTransactionPending(transactionHash?: string): boolean {
  const transactions = useAllTransactions();

  if (!transactionHash || !transactions[transactionHash]) return false;

  return !transactions[transactionHash].receipt;
}

export function useIsTransactionConfirmed(transactionHash?: string): boolean {
  const transactions = useAllTransactions();

  if (!transactionHash || !transactions[transactionHash]) return false;

  return Boolean(transactions[transactionHash].receipt);
}

/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx: TransactionDetails): boolean {
  return new Date().getTime() - tx.addedTime < 86_400_000;
}

// returns whether a token has a pending approval transaction
export function useHasPendingApproval(
  tokenAddress: string | undefined,
  spender: string | undefined,
): boolean {
  const allTransactions = useAllTransactions();
  return useMemo(
    () =>
      typeof tokenAddress === 'string' &&
      typeof spender === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash];
        if (!tx) return false;
        if (tx.receipt) {
          return false;
        } else {
          if (tx.info.type !== TransactionType.APPROVAL) return false;
          return (
            tx.info.spender === spender &&
            tx.info.tokenAddress === tokenAddress &&
            isTransactionRecent(tx)
          );
        }
      }),
    [allTransactions, spender, tokenAddress],
  );
}

// watch for submissions to claim
// return null if not done loading, return undefined if not found
export function useUserHasSubmittedClaim(account?: string): {
  claimSubmitted: boolean;
  claimTxn: TransactionDetails | undefined;
} {
  const allTransactions = useAllTransactions();

  // get the txn if it has been submitted
  const claimTxn = useMemo(() => {
    const txnIndex = Object.keys(allTransactions).find((hash) => {
      const tx = allTransactions[hash];
      return tx.info.type === TransactionType.CLAIM && tx.info.recipient === account;
    });
    return txnIndex && allTransactions[txnIndex] ? allTransactions[txnIndex] : undefined;
  }, [account, allTransactions]);

  return { claimSubmitted: Boolean(claimTxn), claimTxn };
}

export function useTxAddAndCallback(
  response: TransactionResponse,
  info: TransactionInfo,
  callback: () => void,
): void {
  const [done, setDone] = useState(false);
  const isConfirmed = useIsTransactionConfirmed(response.hash);
  const adder = useTransactionAdder();

  useEffect(() => {
    if (isConfirmed && !done) {
      callback();
      setDone(true);
    }
  }, [isConfirmed, done, callback]);
  adder(response, info);
}

export const useCallbackInfos = (): CallbackInfo[] => {
  return useSelector((state: AppState) => state.transactions.callbackInfos);
};

export function useTransactionAdderWithCallback(): (
  response: TransactionResponse,
  info: TransactionInfo,
  callback?: () => void,
) => void {
  const addTransaction = useTransactionAdder();
  const dispatch = useAppDispatch();

  return useCallback(
    (response: TransactionResponse, info: TransactionInfo, onTransactionDone?: () => void) => {
      addTransaction(response, info);
      if (onTransactionDone) {
        dispatch(
          addCallbackInfo({
            func: onTransactionDone,
            hash: response.hash,
          }),
        );
      }
    },
    [addTransaction],
  );
}
