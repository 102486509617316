import { ChainId } from '../interfaces/connection-config.interface';

const { PROD, TEST } = ChainId;

export const MULTISEND_1155__ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0x9ff4b2e9e0b844bfd89ed578a5187b12e22f3e1b',
  [TEST]: '0xd268e89307c67a56aee83700cd3944f02a9783ee',
};

export const MULTIERC20__ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0xcabf370c4b31be34114aeb42375490030599c255',
  [TEST]: '0xb7f9aa05d4622973cd2f2f29c248fe5872833a62',
};

export const MULTIERC721__ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0x3c53e9e0b2176e62849cbc8c060f434d4c5fa7a7',
  [TEST]: '0xf48441d4764a3697aba6201e7289e0cff095d308',
};

export const TOKENLOCKER_FACTORY__ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0xfbabb6b97097ff442426014fe3319575d0727eac',
  [TEST]: '0x22232cca64c263d1bc90013fe720a15f0d4d6178',
};

export const LP_TOKENLOCKER_FACTORY__ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0xf3b50ee4e8c4f5471f8a080e160584ba27d0be7e',
  [TEST]: '0x45e4cc1583a905f20b83b963a8bafd59fddc554b',
};

export const GENERATOR_ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0x1d2021d71e927db36636cf2f28fe835adb0135a9',
  [TEST]: '0xa2aef9c3a529894a68b2a621835224bda740f253',
};

export const PRESALE_FACTORY_ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0x6a34eb483ce14651f25e29096fd69dd3b4ae3654',
  [TEST]: '0x7288b73ee5a5085d86c778de9b2b3315c5c950ec',
};

export const MULTICALL_ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0x2aE7DcaF1e1AEf5Be1Ef63FCb0E70a519A4b8d7E',
  [TEST]: '0xdA0541ae853Ec682Edb79E6AB86DB963E3047e7a',
};

export const XTT_ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0x17476dc3eda45aD916cEAdDeA325B240A7FB259D',
  [TEST]: '0x02E42652b0b85B141B99585C8c2bB17aB305A4c0',
};

export const XSP_ADDRESS: { [chainId in ChainId]: string } = {
  [PROD]: '0x36726235dadbdb4658d33e62a249dca7c4b2bc68',
  [TEST]: '',
};

export const XTT_FAUCET: { [chainId in ChainId]: string } = {
  [PROD]: '0x854f940fFC9791911930c485c040A5164ace8b11',
  [TEST]: '0x854f940fFC9791911930c485c040A5164ace8b11',
};

export const EMPTY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.PROD]: '',
  [ChainId.TEST]: '',
};

export const CRIST_PRESALE_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.PROD]: '0x06B3D5242A5D840FA954662Ce7aB62B14B27177c',
  // [ChainId.PROD]: '0x43f0e9327a1053599cbd487f97a6f68607cb5b8c',
  [ChainId.TEST]: '0x9c07d8d82a9db2617cb6deea584756c14dc2649e',
};

export const GMN_PRESALE_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.PROD]: '0x91cad6714f4fca5d1a28ef49c87854f1f331551a',
  [ChainId.TEST]: '0x51B496b38FB54c152166aB59497CCeaf30B9914a',
};

export const GMN_TOKEN_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.PROD]: '0xfbdc801d904d37d85cf998337a53464c19b5ca5b',
  [ChainId.TEST]: '0xbf630e9f558e892CDBd87AA71d1069bBE4B5905B',
};
