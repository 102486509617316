import { L1ChainInfo } from '../constants/chains';

export enum ChainId {
  TEST = 51,
  PROD = 50,
}

export const enum ENetworkType {
  test,
  prod,
}

export interface IContract {
  address: string;
  abi: any;
}

export interface IConnectionConfig extends L1ChainInfo {
  multiERC1155: IContract;
  multiERC20: IContract;
  multiERC721: IContract;
  generator: IContract;
  presaleFactory: IContract;
  presale: IContract;
  tokenLockerFactory: IContract;
  lpTokenLockerFactory: IContract;
  multicall: IContract;
  xttFaucet: IContract;
}
