export const TOKEN_LIST_REPO = 'https://raw.githubusercontent.com/pro100skm/xdc-token-list/master';
export const TESTNET_LOGO_PATH = `${TOKEN_LIST_REPO}/xdc-logo-test.png`;

export const SocialMedia = {
  twitter: {
    name: 'twitter',
    title: 'XDC.sale - All you need for DeFi on XDC Network',
    link: 'https://xdc.sale/mint-token',
    image:
      'https://s1.1zoom.ru/big3/984/Canada_Parks_Lake_Mountains_Forests_Scenery_Rocky_567540_3840x2400.jpg',
    site: '@XTreasureToken',
    description:
      'Generate XRC20. Create presale with vesting. Automatically add liquidity to XSWAP. Lock LPs. Fast and cheap Multisender.',
  },
};
export interface Social {
  blog: string;
  chat: string;
  discord: string;
  facebook: string;
  forum: string;
  github: string;
  gitter: string;
  instagram: string;
  linkedin: string;
  reddit: string;
  slack: string;
  telegram: string;
  twitter: string;
  youtube: string;
}

export const GMNTokenImageUrl = 'https://gmnet.io/master/logo.png';
export const GMNUTokenImageUrl = 'https://gmnet.io/utility/logo.png';
