import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { injected } from '../constants/connectors';
import { useAppSelector } from '../store';
import getConnectorByName from '../utils/getConnectorByName';
import { isMobile } from '../utils/userAgent';
import { trySwitchingNetwork } from '../utils/wallet';

const useEagerConnect = () => {
  const { activate, active } = useWeb3React();
  const [tried, setTried] = useState(false);

  const selectedWallet = useAppSelector((state) => state.user.selectedWallet);

  const isMetaMask = !!window.ethereum?.isMetaMask;

  // then, if that fails, try connecting to an injected connector
  useEffect(() => {
    if (isMobile && isMetaMask) {
      activate(injected, undefined, true).catch(() => {
        setTried(true);

        trySwitchingNetwork(injected, activate);
      });
    } else if (selectedWallet) {
      const connector = getConnectorByName(selectedWallet);
      activate(connector, undefined, true).catch(() => {
        setTried(true);

        trySwitchingNetwork(connector, activate);
      });
    } else {
      setTried(true);
    }
    // if (!tried && !active) {
    //   injected.isAuthorized().then((isAuthorized) => {
    //     if (isAuthorized) {
    //       activate(injected, undefined, true).catch(() => {
    //         setTried(true);
    //       });
    //     } else {
    //       if (isMobile && window.ethereum) {
    //         activate(injected, undefined, true).catch(() => {
    //           setTried(true);
    //         });
    //       } else {
    //         setTried(true);
    //       }
    //     }
    //   });
    // }
  }, [activate, active, isMetaMask, selectedWallet]);

  // wait until we get confirmation of a connection to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
};

export default useEagerConnect;
