import { Web3Provider, JsonRpcSigner } from '@ethersproject/providers';
import { Contract } from 'ethers';
import { isAddress } from 'ethers/lib/utils';
import { AddressZero } from '@ethersproject/constants';
import ERC20_ABI from '../constants/abis/erc20.json';
import ERC721_ABI from '../constants/abis/erc721.json';
import ERC1155_ABI from '../constants/abis/ERC1155.json';
import LP_ERC20_ABI from '../constants/abis/XswapPair.json';
import TOKEN_LOCKER_ABI from '../constants/abis/TokenLocker.json';
import MulticallABI from '../constants/abis/Multicall.json';
import PRESALE_ABI from '../constants/abis/Presale.json';
import { ERC1155, Erc721, Multicall, TokenLocker } from '../constants/abis/types';

import { Presale, Erc20, XswapPair } from '../constants/abis/types';
import { ChainId } from '../interfaces/connection-config.interface';
import { MULTICALL_ADDRESS } from '../constants/addresses';

export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function getERC20Contract(library: Web3Provider, address: string, account?: string): Erc20 {
  return getContract(address, ERC20_ABI, library, account) as Erc20;
}

export function getLpERC20Contract(
  library: Web3Provider,
  address: string,
  account?: string,
): XswapPair {
  return getContract(address, LP_ERC20_ABI.abi, library, account) as XswapPair;
}

export function getERC1155Contract(
  library: Web3Provider,
  address: string,
  account?: string,
): ERC1155 {
  return getContract(address, ERC1155_ABI, library, account) as ERC1155;
}

export function getERC721Contract(
  library: Web3Provider,
  address: string,
  account?: string,
): Erc721 {
  return getContract(address, ERC721_ABI, library, account) as Erc721;
}

export function getPresaleContract(
  library: Web3Provider,
  address: string,
  account?: string,
): Presale {
  return getContract(address, PRESALE_ABI.abi, library, account) as Presale;
}

export function getLockedTokenContract(
  library: Web3Provider,
  address: string,
  account?: string,
): TokenLocker {
  return getContract(address, TOKEN_LOCKER_ABI.abi, library, account) as TokenLocker;
}

export function getMulticallContract(library: Web3Provider, chainId: ChainId): Multicall {
  return getContract(MULTICALL_ADDRESS[chainId], MulticallABI, library) as Multicall;
}
