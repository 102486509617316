import { darken } from 'polished';
import { Check, ChevronDown } from 'react-feather';
import {
  Button as RebassButton,
  ButtonProps as ButtonPropsOriginal,
} from 'rebass/styled-components';
import styled from 'styled-components/macro';
import { RowBetween } from '../../Row';

type ButtonProps = Omit<ButtonPropsOriginal, 'css'>;

export const BaseButton = styled(RebassButton)<
  {
    margin?: string;
    padding?: string;
    width?: string;
    fontWeight?: string;
    fontSize?: string;
    $borderRadius?: string;
    altDisabledStyle?: boolean;
  } & ButtonProps
>`
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  outline: none !important;
  padding: ${({ padding }) => padding ?? '1rem 2rem'};
  background: -o-linear-gradient(350.16deg, #1c61f8 1.75%, #3b97fb 105.22%);
  background: linear-gradient(99.84deg, #1c61f8 0%, #3b97fb 105.22%);
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  cursor: pointer;
  text-transform: none;
  position: relative;
  z-index: 1;

  text-decoration: none;
  flex-wrap: nowrap;

  width: ${({ width }) => width ?? 'fit-content'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '6px'};
  color: ${({ theme }) => theme.text5};
  box-shadow: 0 2px 2px ${({ theme }) => darken(0.1, theme.primary1)};

  &:hover,
  &:focus {
    background: linear-gradient(99.84deg, #2f59f5 0%, #2f59f5 100%);
    text-decoration: none;
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: auto;
    opacity: 50%;
    pointer-events: none;
  }

  will-change: transform;
  transition: box-shadow 0.3s ease-in-out, transform 0.45s ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`;

export const ButtonPrimary = styled(BaseButton)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  font-weight: 500;
  font-size: 20px;
  &:focus,
  &:hover {
    transform: translateY(-1px);
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
  }

  &:active {
    transition: box-shadow 0.28s ease-in-out, transform 0.1s ease;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
    transform: translateY(0px);
  }
  &:disabled {
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`;

export const ButtonLight = styled(BaseButton)`
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.primaryText1};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`;

export const ButtonGray = styled(BaseButton)`
  background-color: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
  }
`;

export const ButtonSecondary = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.primary1};
  background-color: transparent;
  &:focus {
    outline: 0;
    color: ${({ theme }) => theme.primary3};
  }
  &:hover {
    box-shadow: 0 0 3px ${({ theme }) => theme.primary1};
    color: ${({ theme }) => theme.primary3};
  }
  &:active {
    box-shadow: 0 0 1px ${({ theme }) => theme.primary1};
    color: ${({ theme }) => theme.primary3};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`;

export const ButtonOutlined = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.primary1};
  background: white !important;
  color: ${({ theme }) => theme.text1};
  box-shadow: 0 0 1px ${({ theme }) => theme.primary1};

  &:focus {
    box-shadow: 0 1px 3px ${({ theme }) => theme.primary1};
  }
  &:hover {
    box-shadow: 0 1px 3px ${({ theme }) => theme.primary1};
  }
  &:active {
    box-shadow: 0 0 1px ${({ theme }) => theme.primary1};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonYellow = styled(BaseButton)`
  background-color: ${({ theme }) => theme.yellow3};
  color: white;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.yellow3)};
    background-color: ${({ theme }) => darken(0.05, theme.yellow3)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.yellow3)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.yellow3)};
    background-color: ${({ theme }) => darken(0.1, theme.yellow3)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.yellow3};
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonPink = styled(BaseButton)`
  background-color: ${({ theme }) => theme.primary1Reverse};
  color: white;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1Reverse)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1Reverse)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1Reverse)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1Reverse)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1Reverse)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1Reverse};
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonEmpty = styled(BaseButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonText = styled(BaseButton)`
  padding: 0;
  width: fit-content;
  background: none;
  text-decoration: none;
  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    text-decoration: underline;
  }
  &:hover {
    // text-decoration: underline;
    opacity: 0.9;
  }
  &:active {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

const ButtonConfirmedStyle = styled(BaseButton)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text1};
  /* border: 1px solid ${({ theme }) => theme.green1}; */

  &:disabled {
    opacity: 50%;
    background-color: ${({ theme }) => theme.bg2};
    color: ${({ theme }) => theme.text2};
    cursor: auto;
  }
`;

const ButtonErrorStyle = styled(BaseButton)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`;

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />;
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} />;
  }
}

export function ButtonDropdown({
  disabled = false,
  children,
  ...rest
}: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  );
}

export function ButtonDropdownLight({
  disabled = false,
  children,
  ...rest
}: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  );
}

const ActiveOutlined = styled(ButtonOutlined)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.primary1};
`;

const Circle = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary1};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckboxWrapper = styled.div`
  width: 20px;
  padding: 0 10px;
  position: absolute;
  top: 11px;
  right: 15px;
`;

const ResponsiveCheck = styled(Check)`
  size: 13px;
`;

export function ButtonRadioChecked({
  active = false,
  children,
  ...rest
}: { active?: boolean } & ButtonProps) {
  if (!active) {
    return (
      <ButtonOutlined $borderRadius='12px' padding='12px 8px' {...rest}>
        {<RowBetween>{children}</RowBetween>}
      </ButtonOutlined>
    );
  } else {
    return (
      <ActiveOutlined {...rest} padding='12px 8px' $borderRadius='12px'>
        {
          <RowBetween>
            {children}
            <CheckboxWrapper>
              <Circle>
                <ResponsiveCheck
                  size={13}
                  //  stroke={theme.white}
                />
              </Circle>
            </CheckboxWrapper>
          </RowBetween>
        }
      </ActiveOutlined>
    );
  }
}
