import { ChainId } from '../interfaces/connection-config.interface';
import { TOKEN_LIST_REPO } from './links';
// todo: ChainId.XDC_PROD, for prod
export const ALL_SUPPORTED_CHAIN_IDS: ChainId[] = [ChainId.PROD];

export const L1_CHAIN_IDS = [ChainId.PROD] as const;

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number];

export interface L1ChainInfo {
  readonly blockWaitMsBeforeWarning?: number;
  readonly docs: string;
  readonly explorer: string;
  readonly infoLink: string;
  readonly label: string;
  readonly logoUrl: string;
  readonly rpcUrls: string[];
  readonly chainId: ChainId;
  readonly nativeCurrency: {
    name: string; // 'Goerli ETH',
    symbol: string; // 'gorETH',
    decimals: number; //18,
  };
}

export type ChainInfo = { readonly [chainId: number]: L1ChainInfo } & {
  readonly [chainId in SupportedL1ChainId]: L1ChainInfo;
};

const { PROD, TEST } = ChainId;
export const CHAIN_INFO: ChainInfo = {
  [PROD]: {
    docs: 'https://howto.xinfin.org/',
    explorer: 'https://explorer.xinfin.network',
    infoLink: 'https://xinfin.network/',
    label: 'XinFin Mainnet',
    logoUrl: `${TOKEN_LIST_REPO}/xdc-logo.png`,
    rpcUrls: ['/rpc', 'https://erpc.xinfin.network'],
    chainId: PROD,
    nativeCurrency: {
      name: 'XDC',
      symbol: 'XDC',
      decimals: 18,
    },
  },
  [TEST]: {
    docs: 'https://howto.xinfin.org/',
    explorer: 'https://explorer.apothem.network',
    infoLink: 'https://xinfin.network/',
    label: 'Apothem Testnet',
    logoUrl: `${TOKEN_LIST_REPO}/xdc-logo-test.png`,
    rpcUrls: ['/rpc-test', 'https://rpc.apothem.network'],
    chainId: TEST,
    nativeCurrency: {
      name: 'TXDC',
      symbol: 'TXDC',
      decimals: 18,
    },
  },
};
