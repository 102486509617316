import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { load, save } from 'redux-localstorage-simple';
import { useDispatch } from 'react-redux';
import ui from './ui/reducer';
import application from './application/reducer';
import transactions from './transactions/reducer';
import user from './user/reducer';
import payment from './payment/reducer';
import { multicall } from './multicall/instance';
import presale from './presale/reducer';
const PERSISTED_KEYS: string[] = ['user', 'transactions'];

const listenerMiddleware = createListenerMiddleware();

const store = configureStore({
  reducer: {
    ui,
    application,
    transactions,
    user,
    presale,
    multicall: multicall.reducer,
    payment,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false })
      .prepend(listenerMiddleware.middleware)
      .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
  preloadedState: load({
    states: PERSISTED_KEYS,
    disableWarnings: process.env.NODE_ENV === 'test',
  }),
});

export type AppState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;
