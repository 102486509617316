import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IWithdraw } from './presales/types';

axios.defaults.baseURL = '/api';

axios.interceptors.response.use(async (response) => {
  return response;
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string, config?: AxiosRequestConfig) =>
    axios.get<T>(url, config).then(responseBody),
  post: <T>(url: string, body: any) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: any) => axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Presales = {
  withdraw: (data: IWithdraw) =>
    requests.post<{ signature: string; amount: number }>(`/withdraw/`, data),
  getBalance: (wallet: string) =>
    requests.post<{ current: number; total: number }>('/get-balances/', { wallet }),
  updateBalance: (wallet: string) =>
    requests.post<{ current: number; total: number }>('/update-balance/', { wallet }),
};

interface IGetXDC {
  wallet: string;
  timestamp: string;
  signature: string;
}

const FreeXDC = {
  getXDC: ({ wallet, timestamp, signature }: IGetXDC) =>
    requests.post<{ status: number; txId: string }>('/free-xdc/', {
      wallet,
      timestamp,
      signature,
    }),
};
const agent = {
  Presales,
  FreeXDC,
};

export default agent;
