import { Web3Provider } from '@ethersproject/providers';
import { Contract } from 'ethers';
import { useCallback, useMemo } from 'react';
import { getPresaleContract, getContract } from '../utils/contract';
import useActiveWeb3React from './useActiveWeb3React';
import useDebounce from './useDebounce';
import {
  ContractGenerator,
  PresaleFactory,
  Presale,
  Multicall,
  TokenLockerFactory,
  Erc20,
  MultiERC1155,
  MultiERC20,
  MultiERC721,
  XTTFaucet,
} from '../constants/abis/types';
import { useConnectionConfig } from './useConnectionConfig';
import ERC20_ABI from '../constants/abis/erc20.json';
import { getChainedAddress } from '../utils/getChainedAddress';
import { XTT_ADDRESS } from '../constants/addresses';

export function useContract<T = Contract>(
  getContractFunction: (library: Web3Provider, account?: string) => T,
): T | null {
  const { account, chainId, library } = useActiveWeb3React();

  return useDebounce(
    useMemo((): T | null => {
      if (!library || !chainId) {
        return null;
      }
      const contract: T | null = getContractFunction(library, account || undefined);
      if (!contract) {
        return null;
      }

      return contract;
    }, [getContractFunction, account, chainId, library]),
    100,
  );
}

export function useMultiERC1155Contract(): MultiERC1155 | null {
  const { multiERC1155 } = useConnectionConfig();

  const multisend1155Contract = useCallback(
    (library: Web3Provider, account?: string): MultiERC1155 => {
      return getContract(multiERC1155.address, multiERC1155.abi, library, account) as MultiERC1155;
    },
    [multiERC1155],
  );
  return useContract<MultiERC1155>(multisend1155Contract);
}

export function useMultiERC20Contract(): MultiERC20 | null {
  const { multiERC20 } = useConnectionConfig();
  const multiERC20Contract = useCallback(
    (library: Web3Provider, account?: string): MultiERC20 => {
      return getContract(multiERC20.address, multiERC20.abi, library, account) as MultiERC20;
    },
    [multiERC20],
  );
  return useContract<MultiERC20>(multiERC20Contract);
}

export function useMultiERC721Contract(): MultiERC721 | null {
  const { multiERC721 } = useConnectionConfig();

  const multiERC721Contract = useCallback(
    (library: Web3Provider, account?: string) => {
      return getContract(multiERC721.address, multiERC721.abi, library, account) as MultiERC721;
    },
    [multiERC721],
  );

  return useContract<MultiERC721>(multiERC721Contract);
}
export function useGeneratorContract(): ContractGenerator | null {
  const { generator } = useConnectionConfig();

  const generatorContract = useCallback(
    (library: Web3Provider, account?: string) => {
      return getContract(generator.address, generator.abi, library, account) as ContractGenerator;
    },
    [generator],
  );

  return useContract<ContractGenerator>(generatorContract);
}

export function usePresaleFactoryContract(): PresaleFactory | null {
  const { presaleFactory } = useConnectionConfig();
  const presaleFactoryContract = useCallback(
    (library: Web3Provider, account?: string) => {
      return getContract(
        presaleFactory.address,
        presaleFactory.abi,
        library,
        account,
      ) as PresaleFactory;
    },
    [presaleFactory],
  );
  return useContract<PresaleFactory>(presaleFactoryContract);
}

export function usePresaleContract(address: string | null): Presale | null {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!library || !address) return null;

    try {
      return getPresaleContract(library, address, account ?? '');
    } catch (e) {
      console.error(`Error while getting presale contract with address ${address}\n`, e);

      return null;
    }
  }, [library, address, account]);
}

export function useTokenLockerFactory(isLP = false): TokenLockerFactory | null {
  const { tokenLockerFactory, lpTokenLockerFactory } = useConnectionConfig();
  const lockerFactoryContract = useCallback(
    (library: Web3Provider, account?: string) => {
      return getContract(
        isLP ? lpTokenLockerFactory.address : tokenLockerFactory.address,
        isLP ? lpTokenLockerFactory.abi : tokenLockerFactory.abi,
        library,
        account,
      ) as TokenLockerFactory;
    },
    [tokenLockerFactory, lpTokenLockerFactory, isLP],
  );

  return useContract<TokenLockerFactory>(lockerFactoryContract);
}

export function useMulticallContract(): Multicall | null {
  const { multicall } = useConnectionConfig();
  const multi = useCallback(
    (library: Web3Provider, account?: string) => {
      return getContract(multicall.address, multicall.abi, library, account) as Multicall;
    },
    [multicall],
  );
  return useContract<Multicall>(multi);
}

export function useUtilityTokenContract(): Erc20 | null {
  const { chainId } = useActiveWeb3React();
  const xttContract = useCallback(
    (library: Web3Provider, account?: string) => {
      return getContract(
        getChainedAddress(XTT_ADDRESS, chainId),
        ERC20_ABI,
        library,
        account,
      ) as Erc20;
    },
    [chainId],
  );
  return useContract<Erc20>(xttContract);
}

export function useUtilityTokenFaucetContract(): XTTFaucet | null {
  const { xttFaucet } = useConnectionConfig();
  const xttFaucetContract = useCallback(
    (library: Web3Provider, account?: string) => {
      return getContract(xttFaucet.address, xttFaucet.abi, library, account) as XTTFaucet;
    },
    [xttFaucet],
  );
  return useContract<XTTFaucet>(xttFaucetContract);
}
